
import { defineComponent, onMounted, ref } from "vue";
import Avaliacao from "@/interfaces/Avaliacao";
import BaseService from "@/services/admin/BaseService";
import TipoAvaliacao from "@/interfaces/TipoAvaliacao";
import Disciplina from "@/interfaces/Disciplina";

export default defineComponent({
  props: { id: { type: String, required: false } },
  setup() {
    const loading = ref<boolean>(false);
    const disciplinas = ref<Disciplina[]>([]);
    const avaliacoes = ref<any[]>();
    const avaliacoesAtivas = ref<Avaliacao[]>();
    const tipoDisponivel = ref<(string | undefined)[] | undefined>([]);
    const avaliacao = ref<Avaliacao>();
    const tipo = ref<string>("");
    const disciplinaId = ref<string>("");
    const avaliacoesTipoDisciplina = ref<Avaliacao[]>([]);
    const tiposAvaliacoes = ref<TipoAvaliacao[]>([
      {
        id: "",
        descricao: "Verificação",
        nome: "verificacao",
        created_at: new Date(),
        updated_at: new Date(),
      },
      {
        id: "",
        descricao: "Superação",
        nome: "superacao",
        created_at: new Date(),
        updated_at: new Date(),
      },
      {
        id: "",
        descricao: "Simulado",
        nome: "simulado",
        created_at: new Date(),
        updated_at: new Date(),
      },
      {
        id: "",
        descricao: "Revisão",
        nome: "lista_de_revisao",
        created_at: new Date(),
        updated_at: new Date(),
      },
    ]);

    const mudarTipo = (novoTipo: string): void => {
      tipo.value = novoTipo;
      if (disciplinaId.value != "") {
        avaliacoesTipoDisciplina.value =
          avaliacoes.value?.filter(
            (a: any) =>
              a.disciplina.id == disciplinaId.value &&
              a.tipoAvaliacao?.nome == tipo.value
          ) ?? [];
      }
    };

    const mudarDisciplinaId = (id: string): void => {
      disciplinaId.value = id;
      avaliacoesTipoDisciplina.value =
        avaliacoes.value?.filter(
          (a: any) =>
            a.disciplina.id == id && a.tipoAvaliacao?.nome == tipo.value
        ) ?? [];
    };

    const getMeusCursos = (): void => {
      loading.value = true;
      BaseService.list("aluno/disciplinas-com-avaliacao")
        .then((res) => {
          disciplinas.value = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    };

    const getAvaliacoes = (): void => {
      loading.value = true;
      BaseService.list("aluno/minhas-avaliacoes")
        .then((res) => {
          avaliacoes.value = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    };

    const getAvaliacoesAtivas = (): void => {
      loading.value = true;
      BaseService.list("aluno/minhas-avaliacoes-ativas")
        .then((res) => {
          avaliacoesAtivas.value = res.data;
          // avaliacoesAtivas.value = avaliacoes.value?.filter(
          //   (a) => a.disciplina != null
          // );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    };

    const formatData = (data: string): string => {
      return `${data.substr(8, 2)}/${data.substr(5, 2)}/${data.substr(0, 4)}`;
    };
    onMounted(() => {
      getMeusCursos();
      getAvaliacoes();
      getAvaliacoesAtivas();
    });

    return {
      loading,
      avaliacao,
      avaliacoes,
      disciplinas,
      tiposAvaliacoes,
      tipo,
      disciplinaId,
      avaliacoesTipoDisciplina,
      mudarDisciplinaId,
      getMeusCursos,
      getAvaliacoes,
      mudarTipo,
      avaliacoesAtivas,
      tipoDisponivel,
      formatData,
    };
  },
});
